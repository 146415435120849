import "./body.css";

function Footer() {
    return (
        <section className="footerbody">
            <p className="centeredp">&copy;Josiah Swanson 2024</p>
        </section>
    );
}

export default Footer;
