import "./body.css";
import Header from "../components/header";
import Footer from "../components/footer";

import React, { useEffect } from "react";

function ChristianTech() {
    useEffect(() => {
        document.title = "Josiah Swanson - The Technology of Christianity";
    }, []);
    return (
        <>
            <Header />
            <section className="mainbody">
                <h2>The Technology of Christianity</h2>
                <h3>The Purpose of this Essay</h3>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Christianity and
                    technology are topics that most would not naturally associate with
                    each other. Yet, the technology of Christianity is a garden in which
                    grows all kinds of plants, some of which have been growing for
                    thousands of years. The purpose of this essay is to acknowledge this
                    growth, and to describe it. Furthermore, this essay exists to provide
                    a warning concerning the weeds which grow in this garden. Lastly, this
                    essay proposes a framework by which to address the future of the
                    garden.
                </p>
                <h3>Definition</h3>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Before we go any
                    further, let's agree on some definitions.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Two thousand years
                    ago, Jesus Christ died on a cross, punished for blasphemy in a Roman
                    execution. A few days later, he allegedly reappeared and commissioned
                    his followers to make disciples and to teach them his commands. This
                    commissioning is the foundation of Christianity, and it presumes
                    Scripture's authority over the life of the commissioned. Therefore,
                    Christianity is the religion that adheres to the teachings of Jesus
                    Christ with respect to the Bible and promotes the making of disciples
                    in His name.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for technology, we
                    may use Peter Thiel's definition from his book&nbsp;
                    <em>Zero to One</em>: "any new and better way of doing things."
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Combing these
                    definitions, we can describe the technology of Christianity as the
                    collection of new and better ways of communicating Jesus Christ's
                    commands and making disciples in His name.
                </p>
                <h3>The History of Christian Technology</h3>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Technology made its
                    first appearance at the birth of church, as the Apostle Paul and
                    others made use of letter-writing, a new and better way to communicate
                    in his time. A few hundred years later, after the formalization of
                    Biblical canon, linguistic technology appeared in the form of
                    alphabet. Latin and Greek's innovations and use in novel vernacular
                    literature not only indicates Christianity's impact on human society
                    at this point in history, but the curiosity and genius of people like
                    Jerome of Stridon, who ushered in new translations, like Latin, to
                    provide the written Bible for many. Another example of linguistic
                    innovation, the King James Bible has contributed to the English
                    language more phrases than any other source. Expressions such as "a
                    fly in the ointment", "thorn in the side", and "seeing eye to eye" all
                    originated in that Bible. John Wycliffe, the man often thought to be
                    responsible for the first attempt at an English translation of the
                    whole Bible, said, "it helpeth Christian men to study the Gospel in
                    that tongue in which they know best Christ's sentence." Probably more
                    impactful on Christianity than any other technology is that of the
                    printing press by Johannes Gutenberg. Before the 15th century, mass
                    production of Bibles and other Christian literature in Europe was not
                    only legally risky&mdash;it was physically impossible. This printing
                    press revolutionized the way information was disseminated and played a
                    significant role in the Protestant Reformation.
                </p>
                <h3>Christian Technology Today</h3>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The exponential nature
                    of technological progress in the past one-hundred years has impacted
                    seemingly every major institution in modern society. Christianity is
                    no exception. No one can say for sure how many new and better ways of
                    communicating the teachings of Jesus Christ have been proposed, even
                    in the last decade. Yet, some examples are clearly revolutionary.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The use of radio and
                    television to broadcast Christian messages to a wider audience has
                    truly impacted the culture of Christianity, especially in America. In
                    the early 20th century, radio preachers such as Charles E. Fuller
                    reached millions of listeners with their messages. Later, television
                    evangelists such as Billy Graham became household names, reaching
                    millions more with their televised crusades and church services.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In recent years, the
                    advent of the internet and social media has created even more
                    opportunities for Christian technology. Online Bible studies,
                    Christian podcasts, and streaming church services have become
                    increasingly popular, allowing people to connect with others and grow
                    in their faith from anywhere in the world.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;David Shormann, a
                    Texan who relocated to Hawaii, has built a business around educational
                    software and a curriculum taught from a Christian perspective. Many
                    concur that the curriculum,{" "}
                    <a rel="noreferrer" target="_blank" href="https://diveintomath.com/">
                        DIVE
                    </a>
                    , is beneficial for faith, helpful for the mind, and altogether fun
                    and compelling, having impacted thousands of homeschooling families.
                    At the center of even his mathematics lessons is the teachings of
                    Jesus, and the gospel message as presented in the New Testament.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a rel="noreferrer" target="_blank" href="https://waha.app/">
                        Waha
                    </a>{" "}
                    is a new mobile app which provides its users with a simple and
                    easy-to-use interface for discovery Bible study facilitation. Jared
                    Mac, one of the founders of Waha, is currently endeavoring to
                    accommodate one hundred languages within the app, so that people from
                    all over the globe can learn about the teachings of Jesus together.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Faithlife, founded in
                    1992, is a company whose self-proclaimed telos is found at the
                    intersection of Christian faith and technology. Their software,{" "}
                    <a rel="noreferrer" target="_blank" href="https://www.logos.com/">
                        Logos
                    </a>
                    , is a powerful tool that millions of Christians use today to
                    understand more deeply the richness of the Biblical text. Preachers
                    and lay-people alike benefit greatly from the beautifully designed
                    user experience that equips them to interact with Scripture with
                    confidence and ease.
                </p>
                <h3>The Dangers of Weeds in the Garden</h3>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;While the technology
                    of Christianity has offered many new and better ways to proclaim and
                    explain Biblical teaching, it has simultaneously offered new and
                    better ways for bad actors and charismatic lunatics to unleash their
                    schemes on all kinds of cultures and people.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One such example is
                    Robert Harold Schuller, the American Christian televangelist, pastor,
                    motivational speaker, and author who was mainly known for hosting the
                    weekly Hour of Power television program for five decades, from 1970 to
                    2010. Schuller was also the founder of the Crystal Cathedral in Garden
                    Grove, California, where the Hour of Power program was later
                    broadcast. Rather than leveraging his resources and influence to
                    communicate Jesus Christ's commands and make disciples in His name, he
                    wrote that, "if you can dream it, you can do it." He disregarded
                    Jesus' teaching on sin and repentance and touted that the main problem
                    in modern society is the mindset adopted by individuals which prevents
                    them from unlocking their true potential. His grandson, Bobby
                    Schuller, now carries on the Hour of Power. Together, their prosperity
                    gospel teachings have reached millions all over the globe.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sometimes, Christian
                    technology is adopted as a substitute for genuine human connection and
                    relationship-building. While online communities and virtual events can
                    be valuable tools for connecting with others, they cannot fully
                    replace the value of face-to-face interactions and the importance of
                    being part of a physical community. Thousands of megachurches swap out
                    relational discipleship for mobile apps and online devotionals.
                </p>
                <h3>A Framework for the Future of the Garden</h3>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Despite these dangers,
                    the technology of Christianity continues to be used properly by many,
                    enabling the legitimate dissemination of Christ's teachings and
                    discipleship efforts. To ensure that the garden of Christian
                    technology continues to prosper, it is crucial to have in mind a
                    framework through which to approach future opportunities and
                    use-cases.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rather than applying
                    anthropologic or psychological wisdom, Christian technology rightly
                    applied will always prefer Biblical wisdom alone. Passages such as 1
                    Corinthians 9 and Hebrews 1 are terrific sources for balanced Biblical
                    wisdom which is particularly helpful for this endeavor.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On one hand, Scripture
                    seems to encourage the use of technology, like in 1 Corinthians 9. The
                    man who wrote this passage is the Apostle Paul, who we discussed
                    earlier when we reflected on Christian technology's first appearance
                    in letter-writing. He was so ambitious to explain Christ's teaching
                    and to make disciples that he wrote, "though I am free from all men, I
                    have made myself a slave to all, so that I may win more&hellip;I have
                    become all things to all men, so that I may by all means save some."
                    Which technologies was Paul willing to use in order that some may be
                    saved? All of them! Indeed, to Paul, the ends justified the means,
                    assuming that he was not sinning in his efforts (see the previous
                    chapter).
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As helpful as
                    technology is, those who use it must be restrained. Hebrews 1:1-4
                    describes that God has spoken "in many ways" throughout time,
                    employing His own technologies to reveal Himself to humans. Yet, "in
                    these last days, [He] has spoken to us in His Son." According to this
                    passage, it would seem that technology's most important use in a
                    Christian context is to assist in communicating that which has already
                    been communicated in Scripture concerning Jesus Christ.
                </p>
                <h3>Closing Thoughts</h3>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In conclusion, the
                    technology of Christianity is a rich and varied garden, with a long
                    history. While there are dangers and challenges to be aware of,
                    ministers of Christ's teaching and His discipleship can see the Bible
                    itself as the God-given framework for understanding and utilizing
                    technology. By embracing this framework, Christian technologists can
                    ensure that the garden will continue to thrive and bear fruit for
                    generations to come.
                </p>
            </section>
            <Footer />
        </>
    );
}

export default ChristianTech;
