import "./body.css";
import Header from "../components/header";
import Footer from "../components/footer";
import subImage from "../photos/sub-marine.png";

import React, { useEffect } from "react";

function Submarine() {
    useEffect(() => {
        document.title = "Josiah Swanson - Sub-Marine";
    }, []);
    return (
        <>
            <Header />
            <section className="mainbody">
                <img src={subImage} alt="Sub-Marine"></img>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sub-Marine is
                    dedicated to{" "}
                    <span title="according to Lewis, imagination is simply 'the organ of meaning'">
                        Imagination
                    </span>
                    . It's a great read for ten to fourteen year-old boys, but other kinds
                    of people have apparently enjoyed it, too. In one sentence, it is a
                    story about two brothers and their godfather, and the undersea
                    adventure they go on when the storm of the century destroys their
                    island. You can{" "}
                    <a
                        href="https://www.amazon.com/dp/1978457707/ref=sr_1_1?ie=UTF8&qid=1513331791&sr=8-1&keywords=Josiah+Swanson"
                        rel="noreferrer"
                        target="_blank"
                    >
                        get the hardcover here
                    </a>{" "}
                    or{" "}
                    <a
                        href="https://www.amazon.com/dp/B0789H96CF/ref=sr_1_37?ie=UTF8&qid=1513391228&sr=8-37&keywords=Sub-Marine"
                        rel="noreferrer"
                        target="_blank"
                    >
                        get it for your Kindle
                    </a>{" "}
                    or{" "}
                    <a
                        href="https://itunes.apple.com/us/book/sub-marine/id1322196182?ls=1&mt=11"
                        rel="noreferrer"
                        target="_blank"
                    >
                        on the Apple Book store
                    </a>
                    .
                </p>
            </section>
            <Footer />
        </>
    );
}

export default Submarine;
