import "./body.css";
import Header from "../components/header";
import Footer from "../components/footer";

import React, { useEffect } from "react";

function Winwin() {
    useEffect(() => {
        document.title = "Josiah Swanson - Win-Win";
    }, []);
    return (
        <>
            <Header />
            <section className="mainbody">
                <h2>Win-Win</h2>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Imagine that you and
                    your friend have just got caught in the act of robbing a bank. Why you
                    have gone to such a moral low I can only wonder. Cheer up, now, they
                    haven't found the money! That's right, you've hid the money in an
                    unsearchable place and may be getting off easy. As you and your friend
                    are taken in for questioning, you're almost ecstatic; your hiding
                    place was so perfectly genius and robbery confounding that you can
                    almost guarantee your head on your favorite down-pillow tonight. But
                    there's a catch: you've been found carrying a firearm illegally and
                    will be sent to prison for an entire year because of it. You were so
                    close! Now some crafty policemen, desperate for evidence, have come up
                    with an idea: they will separate you and your friend and will offer a
                    get-out-of-jail-free card to the one who fesses up. Although keeping
                    your mouth shut seemed like the obvious strategy at first, now you are
                    certainly planning on confession. Just before the interview, you find
                    out one more detail: you only get to go home for free if your friend
                    does not confess&mdash;and sadly for him, he will go to jail for five
                    years for trying to get away with the crime. If you both confess, you
                    will share the punishment and each go to jail for three years, which
                    is not as bad as five. You consider that your friend is probably also
                    planning to confess. This is called the Prisoner's Dilemma, and it
                    shows how likely it is for two entities to wind up with sub-optimal
                    results when they do not cooperate with each other. Whether your
                    friend is planning to confess or not, you have incentive to confess.
                    As a result, the "solution" to this "game," in game theory terms, is
                    for both you and your friend to confess and go to jail for three
                    years, even though you could have both kept your mouths shut and gone
                    for one instead. Is there a way to restructure this game to guarantee
                    you and your friend the better outcome? We will get back to this
                    question once we've examined some evidence.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Let's talk about
                    Tesla. Founded in 2003 and becoming owned by Elon Musk in 2004, Tesla
                    has been rapidly growing for decades and is currently the most
                    valuable automobile manufacturer in the world. With a market
                    capitalization of over $840 billion, Tesla is creating massive wakes
                    in international economic waters by making environmental friendliness
                    fun. Sure, some skeptics have claimed that Tesla's battery
                    manufacturing process is just as harmful as the traditional car,
                    declaring hypocrisy. Yet, it's becoming increasingly clear that{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.wsj.com/graphics/are-electric-cars-really-better-for-the-environment/"
                    >
                        over time Tesla is a relative net-positive for the environment
                    </a>
                    . According to the University of Toronto's analysis, after 20,600
                    miles, a Tesla becomes less environmentally impactful than a RAV4,
                    which continues to emit emissions, while the Tesla does not. To add to
                    Musk's campaign, it must be noted that, when we are all out of fossil
                    fuels, drivers will be thankful that companies like Tesla paved the
                    way for an alternative form of transportation.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Most people probably
                    haven't heard the name Yvon Chouinard before. Yet, his story is very
                    important.{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.nytimes.com/2022/09/14/climate/patagonia-climate-philanthropy-chouinard.html"
                    >
                        He recently made headlines
                    </a>{" "}
                    when he decided to transfer ownership of Patagonia, his clothing
                    company valued at over $3 billion, to a specially designed set of
                    trusts and nonprofit organizations whose aim is to "preserve the
                    company's independence and ensure that all of its profits&mdash;some
                    $100 million a year&mdash;are used to combat climate change and
                    protect undeveloped land around the globe." Like Musk, Chouinard has
                    made his money unconventionally. Patagonia has been known to introduce
                    long-lasting, superior product into the clothing industry and
                    institute policies which keep clothes in circulation rather than
                    contributing to waste. In fact, once Patagonia ran a Black Friday ad
                    in the New York Times which featured one of their popular products and
                    the text, "Don't Buy This Jacket," literally encouraging readers to
                    forego making a purchase they would have otherwise made, to be less
                    wasteful. Challenging the status-quo through participating in
                    campaigns like One Percent for the Planet and suing the American
                    government for encroaching on land reserves, Patagonia is a unique
                    company which is seemingly no worse off for its environmental agenda.
                    As Yvon Chouinard writes in <span>Let My People Go Surfing</span>,
                    "When we make a decision because it's the right thing to do for the
                    planet, it ends up being good for business."
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Social media has
                    played a very important role in modern society. From last night's
                    football game to political discourse to photos at the beach, social
                    media platforms are beckoning content ceaselessly. In the blockbuster
                    documentary The Social Dilemma, some software developers interviewed
                    suggested that the biggest players in the social media competition are
                    employing algorithms that incentivize promiscuity, hatred, image
                    filters, and spending time in-app. Yet, there are exceptions. BeReal,
                    one of the newest social media apps on the market, has surged in
                    popularity recently by simply incentivizing authenticity. Every day,
                    at some random moment, everybody with a BeReal account is pinged with
                    the short message, "it's time to be real!" With that, millions of
                    users upload candid photos of their daily lives, unedited and
                    unashamed. Most people shrug off the brute honesty in their photos
                    because they expect the other users to do the same.{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://sensortower.com/blog/bereal-50m-installs"
                    >
                        The download stats are incredible
                    </a>
                    : 53 million downloads as of October 2022, up from 28 million in
                    August of the same year!
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Why are these stories
                    so exceptional? Why is it so surprising that an entity can do the
                    right thing and be successful? Already noted was social media's
                    ceaseless beckoning for content. More so than content, however, social
                    media platforms desire user engagement. Indeed, social media has one
                    goal: maximize time spent on the app. When companies like TikTok and
                    Facebook consider how to acquire and hold their users' attention, they
                    must employ algorithms. Truly, without the help of inhuman
                    competencies (namely the ability to track users' complex content
                    preferences), a social media platform like Instagram would be left in
                    the dust. There are other facets of competition which are important,
                    yet, moving forward, the algorithms employed by the biggest players
                    will uniquely determine the social media "game" and its "payoffs."
                    Currently, Instagram, the leading social media platform,{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://later.com/blog/how-instagram-algorithm-works/"
                    >
                        runs its algorithm on engagement alone
                    </a>
                    . This means that users and their posts will be pushed by the platform
                    primarily on the basis of likes, comments, and follows. There is a
                    whole host of datapoints which the algorithm either ignores or does
                    not track because it is unimportant to both parties in their
                    short-term pursuit yet may prove to be very important to users in the
                    long-term: user authenticity, environmental curiosity, and moral
                    conduct to name a few. Ultimately, certain sacrifices made by social
                    media platforms in their pursuit of revenue are inherited by their
                    users and negatively impact them over time.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Politics is a
                    fascinating game, because it is in some sense played by teams, but is
                    also practically individual-focused. In 1880, James A. Garfield just
                    about lost the presidency because of a divided party. The incumbent
                    Rutherford B. Hayes had chosen not to go for his second term, leaving
                    his party to choose from swath of eager candidates. The "Grand Old
                    Party" proceeded to grind out a seven-day marathon in which no
                    candidate could acquire the majority votes. After 34 ballots, Garfield
                    emerged. We can't know for sure what was in the mind of each of the
                    candidates, yet it is safe to assume that some of them valued a
                    Republican victory over a Democratic one, even if it meant their own
                    individual loss. Yet, history shows that cooperation is very difficult
                    when an individual sees the game he or she is playing as one in the
                    zero-sum category. Though the ultimate goal may be to see his own
                    party win, a candidate may get lost in the sauce, as the saying goes,
                    and hand the victory to the other team because, to him, either he
                    wins, or nobody does!
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The main reason it is
                    so surprising that a business can do the right thing and be successful
                    is because it so seldom happens. Corporate America (and, in some ways,
                    the whole world) has become increasingly individualistic, with many
                    players (both humans and corporations) adopting a zero-sum mentality.
                    As Yvon Chouinard writes in <span>Let My People Go Surfing</span>,
                    "The thought is: I'm not the problem, therefore, I'm not the solution.
                    'If we don't use industrially grown GMO cotton, then we won't be able
                    to compete.' 'If we don't buy the world's dirtiest oil from the
                    Alberta tar sands, then we will be at the mercy seat of buying it from
                    the Arabs.' 'If America doesn't sell armaments to (choose your
                    favorite dictatorship), then they will buy from the French or
                    Russians.' 'Why should we cut back on fossil fuels until the Chinese
                    and Indians do?'" Automobile manufacturers, clothing companies, and
                    social media platforms are often competing for scarce resources with
                    seemingly little room to breathe let alone to think about the far
                    future and the long-term effects their behavior might contribute
                    towards. It is in this modern context that algorithms prowl and
                    politicians scrap. There are today many examples of instrumental
                    convergence, the idea that two intelligent agents can have the same
                    sub-goal though their ultimate goals are different. In the case of
                    social media, the corporate entity called Instagram employs an
                    algorithm which shares with its users the goal of maximizing
                    engagement, even though the ultimate goal of the business (and its
                    algorithm) is to make money, and the goal of the users is, well,
                    unique to each user, but probably not to make Instagram money. In the
                    case of politics, the candidate and his or her supporters share the
                    same short-term goal: election! Yet, for some politicians, the sweet
                    taste of victory and the view from the Oval Office is the long-term
                    goal, whereas their supporters may be much more concerned with their
                    party's success, regardless of the individual elected. These stories
                    describe the expected behavior of entities involved in a Prisoner's
                    Dilemma. How did they find themselves playing a game where the
                    sub-optimal results are incentivized? Surely, they did not willfully
                    participate! Given that none of the players described wished to play
                    the game, we can assume that each game was influenced by some outside
                    force. And, what a dark and mysterious force this must be, given it is
                    bent on the demise of the players of the game! Let's call him Moloch.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Liv Boree is a former
                    poker player and game theorist hailing from Kent, UK. In a podcast
                    interview with Lex Fridman, Boree introduced Moloch by referencing{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://allenginsberg.org/2011/04/moloch-2/"
                    >
                        a poem written about him by Allen Ginsberg
                    </a>
                    . It's a grim and depressing account, and it is eerily reminiscent of
                    certain shades of American capitalism. Boree's objective was not to
                    make a case for the evils of capitalism, but to peak behind the
                    curtain and shine a beam onto the machinery and onto the mysterious
                    face of the machinist, one characterized by his desire to encourage
                    unnecessary sacrifices. Also, she and Lex went on to talk about the
                    Paperclip Maximizer, an entity who is so set on a singular goal that
                    everything in the universe eventually becomes enveloped in its
                    machinery. Of course, no one really believes that there is an
                    intelligent being behind the darkness&mdash;yet, untold multitudes of
                    games have been subtly transforming into Prisoner's Dilemmas,
                    oftentimes unbeknownst to the players. Talking about the Prisoner's
                    Dilemma and anthropomorphizing it makes sense for the same reason that
                    it makes sense to name a certain kind of cancer and understand its
                    causes. Once named and understood, the problem can be seen more
                    clearly in many different places, including new ones.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If we are going to
                    defeat Moloch, we will need to understand his behavior. Once upon a
                    time, the automobile was introduced to the world and changed
                    everything about modern society. Suddenly, individuals could get from
                    point A to point B autonomously, not bound by third parties or
                    politics. For a while, it seemed that everyone won. It was in the
                    automobile industry's infancy that William Morrison, a chemist from
                    Des Moines, Iowa, manufactured some of the first electric vehicles. By
                    1890, electric cars were at their heyday, accounting for around a
                    third of all vehicles on the road! However, at some point, Moloch
                    began distorting the underlying game; the inexpensive Model T was
                    introduced and, suddenly, fuel-run cars were normative. It was not
                    long before the automobile industry was stuck in a Prisoner's Dilemma.
                    In this game, the "prisoners" are members of the automobile industry.
                    Typically, one member harms the environment by competing with another
                    member on the basis that fuel consumption (thus, CO2 emission) is a
                    normative aspect of being a competitor in the industry. If company X
                    introduces 100 new cars into the market (Confess), company Y will feel
                    pressure to compete for prospective customers by introducing 100 cars
                    as well (also Confess). Otherwise, company Y will not expect to
                    survive economically. Yet, neither considered that it may be possible
                    to become the superior vendor over time by investing in a technology
                    which is both more attractive to customers and better for the
                    environment. This possibility exists only within a different game
                    where the payoffs have reverted, namely the Not-Confess payoff. In
                    other words, one must incentivize good behavior to realistically
                    expect it again. At some point, the good behavior may have been
                    normatively incentivized but, thanks to Moloch, today it is not.
                    Truly, he is the god of sacrifice, and he will not stop demanding more
                    from the prisoners, constantly incentivizing worse and worse outcomes,
                    until the players are competing just to survive.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Let's come back to the
                    Prisoner's Dilemma about the bank robbery as we planned. Can we
                    restructure the game to guarantee you and your friend the better
                    outcome? One way is to imagine that you were sentenced to one year in
                    prison regardless of your confession. With no reward awaiting you for
                    switching your strategy, you decide to keep your mouth shut,
                    especially because you believe that your friend will come to the same
                    conclusion. Another way to restructure the game is to hide your gun
                    with the money. With no leverage on the part of the police, they have
                    no way to punish you if you keep your mouth shut. Now, you are
                    deciding between your down-pillow and years in prison. Easy choice!
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moloch demands
                    unnecessary sacrifices, like increasing CO2 emissions, wasting
                    clothes, and catalyzing divisiveness. Today, America (and many parts
                    of the world) is full of unnecessary sacrifice. Is this unusual? Some
                    would argue in the negative. Moloch's Lose-Lose approach is seen in
                    the broader animal kingdom. For instance, lions often fight with each
                    other rather than team up to hunt more prey. An understanding of
                    Moloch's strategy is both depressing and hopeful. It is depressing
                    because the games he builds are always destructive and harmful to the
                    players. What is so hopeful, however, is that there still exists the
                    Confess-Confess strategy! To realistically expect the benefits of this
                    strategy, players must be bold; they must listen to their own kind of
                    music. Humans are unique in their ability to understand the difference
                    between a necessary sacrifice and one that can be avoided. Sometimes,
                    entities are so lazy and unimaginative that they give up before they
                    even try playing a new game. Yet, those who are bold enough and
                    strange enough to begin playing new games in isolation will soon find
                    other players joining them. Sometimes, the game is brought to its
                    original state (like with automobiles). Other times, the game is
                    released from Moloch's tight clutch for the first time (try to think
                    of some of your own examples).
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It must be noted that
                    this call to action goes to the influencers first. Imagine you are at
                    a concert, and that everybody in the audience is standing up. To enjoy
                    the performance, you must stand up too. Sitting down may be the more
                    comfortable way to enjoy the show, but it wouldn't make sense to sit
                    until everyone in front of you sat down. Similarly, entities with
                    influence must be the first movers, lest Moloch continues his reign.
                    Beautifully, capitalism is the perfect weapon against Moloch. Although
                    Moloch often manifests himself though enlarging profit margins, it has
                    already been proven that influential capitalistic entities have been
                    done some of the greatest damage to the god of sacrifice.
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If entities continue
                    to sleep through Moloch's power-grab, the future is bleak. However,
                    assuming that Tesla, Patagonia, and BeReal are just the first fruits
                    of the 21st century's Win-Wins, there may be much to hope for.
                    Regulations can be enforced that prevent entities from desiring to
                    choose the Confess strategy (Regulators in California are currently
                    attempting to make it more difficult to purchase new fuel-consuming
                    vehicles). One might recognize an underlying ethical problem: entities
                    are greedy and self-centered. This is true. Yet, doing away with
                    capitalism is not necessarily the solution. Arguably, there are enough
                    people on this planet that we can confidently hope that some in power,
                    some with influence, will be game changers. Often it is those among us
                    who are more morally grounded, more convicted, more determined, who
                    can effectively convince the prisoners that they don't need to go to
                    jail. When they emerge from the chaos of normative sacrifice, we ought
                    to cheer them on. We ought to join them!
                </p>
            </section>
            <Footer />
        </>
    );
}

export default Winwin;
