import "./body.css";
import Header from "../components/header";
import Footer from "../components/footer";

import React, { useEffect } from "react";

function Unfound() {
    useEffect(() => {
        document.title = "Josiah Swanson - 404";
    }, []);
    return (
        <>
            <Header />
            <section className="mainbody">
                <p className="centeredp">Page not found.</p>
            </section>
            <Footer />
        </>
    );
}
export default Unfound;
