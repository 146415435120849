import "./body.css";
import "./photos.css";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Photos() {
    const navigate = useNavigate();
    function shuffle(array) {
        let currentIndex = array.length,
            randomIndex;
        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex],
                array[currentIndex],
            ];
        }
        return array;
    }
    let photoList = [
        {
            key: 1,
            imagename: "Rincon",
            location: "11",
        },
        {
            key: 2,
            imagename: "Friends",
            location: "7",
        },
        {
            key: 3,
            imagename: "Makaha",
            location: "1",
        },
        {
            key: 4,
            imagename: "Yosemite",
            location: "8",
        },
        {
            key: 5,
            imagename: "Pipeline",
            location: "3",
        },
        {
            key: 6,
            imagename: "Tidepool",
            location: "9",
        },
        {
            key: 7,
            imagename: "Surfing",
            location: "10",
        },
        {
            key: 8,
            imagename: "Firework",
            location: "2",
        },
        {
            key: 9,
            imagename: "Sunset",
            location: "4",
        },
        {
            key: 10,
            imagename: "Cloud",
            location: "5",
        },
        {
            key: 11,
            imagename: "Palms",
            location: "6",
        },
    ];
    const images = shuffle(photoList).map((image) => {
        return (
            <img
                key={image.key}
                src={require(`../photos/${image.location}.jpg`)}
                alt={image.imagename}
                className={image.imagename}
            />
        );
    });
    useEffect(() => {
        document.title = "Josiah Swanson - Photos";
    }, []);
    return (
        <>
            <button onClick={() => navigate(-1)}>Go back</button>
            <section className="photosbody">{images}</section>
        </>
    );
}

export default Photos;
