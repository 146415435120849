import "./body.css";
import "./about.css";
import Header from "../components/header";
import Footer from "../components/footer";
import aboutImage from "../photos/about.jpg";

import React, { useState, useEffect } from "react";

function About() {
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  let [aboutList] = useState([
    "Hello world!",
    "Cheers!",
    "Welcome to my website!",
    "How goes it?",
  ]);
  let [aboutMessage, setAboutMessage] = useState(aboutList[0]);
  useEffect(() => {
    let randomIndex = getRandomInt(aboutList.length);
    setAboutMessage(aboutList[randomIndex]);
    document.title = "Josiah Swanson - About";
  }, [aboutList]);
  return (
    <>
      <Header />
      <section style={{ minHeight: "500px" }}>
        <section className="mainbody">
          <section className="aboutleft">
            <img src={aboutImage} alt="About"></img>
          </section>
          <section className="aboutright">
            <p>
              {aboutMessage}
              <br></br>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/in/josiah-swanson-5251a0188"
              >
                LinkedIn
              </a>{" "}
              /{" "}
              <a
                target="_blank"
                href="https://salmon-ocean-0c7fa0a1e.azurestaticapps.net/"
                rel="noreferrer"
              >
                Old Site
              </a>
            </p>
          </section>
        </section>
      </section>
      <Footer />
    </>
  );
}

export default About;
