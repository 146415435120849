import "./header.css";
import { NavLink } from "react-router-dom";

function Navigation() {
    return (
        <nav>
            <ul>
                <li>
                    <NavLink
                        to="/about"
                        className={({ isActive }) =>
                            isActive ? "selectedlink" : "notselectedlink"
                        }
                    >
                        About
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/winwin"
                        className={({ isActive }) =>
                            isActive ? "selectedlink" : "notselectedlink"
                        }
                    >
                        Win-Win
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink
                        to="/christiantech"
                        className={({ isActive }) =>
                            isActive ? "selectedlink" : "notselectedlink"
                        }
                    >
                        The Technology of Christianity
                    </NavLink>
                </li> */}
                <li>
                    <NavLink
                        to="/submarine"
                        className={({ isActive }) =>
                            isActive ? "selectedlink" : "notselectedlink"
                        }
                    >
                        Sub-Marine
                    </NavLink>
                </li>
                <li>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://beholdchristdaily.com/daily"
                    >
                        Behold Christ
                    </a>
                </li>
                {/* <li>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://charkl.josiahswanson.com"
                    >
                        Charkl
                    </a>
                </li> */}
                <li>
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="http://investwithknowledge.com"
                    >
                        Invest with Knowledge!
                    </a>
                </li>
                <li>
                    <NavLink
                        rel="noreferrer"
                        to="/photos"
                        className={({ isActive }) =>
                            isActive ? "selectedlink" : "notselectedlink"
                        }
                    >
                        Photos
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}

function Header() {
    return (
        <section className="header">
            <h1>Josiah Swanson</h1>
            <Navigation />
        </section>
    );
}

export default Header;
